import { ref } from "@vue/composition-api";
import store from "@/store/index"

const useAuctions = (idCategory = '0', router) => {

    // base data
    const auctions = ref([]);
    const typeAuction = ref({});
    const search = ref("");
    const loading = ref(false);

    // methods
    const openCreate = async () => {
        router.push(`/auctions/category/${idCategory}/form`)
    }

    const openEdit = async (auction) => {
        router.push(`/auctions/category/${idCategory}/form/${auction.id}`)
    }

    const getTypeAuctionById = async () => {
        const { data, status } = await store.dispatch("fetchAuctionsTypeById", idCategory)
        if (status != 200) return;
        typeAuction.value = data;
    }

    const getAuctionsListByCategory = async () => {
        const { data, status } = await store.dispatch("fetchAuctionsByCategory", idCategory)
        if (status != 200) return;
        auctions.value = data;
    }

    const removeAuction = async (auction) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeAuction", auction)
        if (status != 200 && status != 201 && status != 204) return;
        await getAuctionsListByCategory();
    }

    getTypeAuctionById();
    getAuctionsListByCategory();

    return {
        auctions,
        typeAuction,
        search,
        loading,
        // methods
        openCreate,
        openEdit,
        changeSearch(ev) {
            search.value = ev
        },
        openRemove: removeAuction,
    };
}

export default useAuctions;