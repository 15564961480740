<template>
  <v-card>
    <AuctionHeader @openToAdd="openCreate" @search="changeSearch" :title="typeAuction.title_auctions"></AuctionHeader>
    <DataTableAuctions
      :auctions="auctions"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DataTableAuctions>
  </v-card>
</template>

<script>
import DataTableAuctions from '@/components/data-table/DataTableAuctions.vue'
import AuctionHeader from './AuctionHeader.vue'
import useAuction from '@/composables/useAuction'

export default {
  components: {
    DataTableAuctions,
    AuctionHeader,
  },
  setup(props, context) {
    const router = context.root.$router
    const route = context.root.$route
    const idCategory = route.params.idCategory
    // Composition API
    const {
      dialog,
      auctions,
      typeAuction,
      search,
      loading,
      // methods
      openCreate,
      openEdit,
      changeSearch,
      openRemove,
      // crud
    } = useAuction(idCategory, router)

    return {
      dialog,
      auctions,
      typeAuction,
      search,
      loading,
      // methods
      openCreate,
      openEdit,
      changeSearch,
      openRemove,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
