<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="auctions"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height"
      fixed-header
      disable-sort
    >
      <!-- name -->
      <template #[`item.title_auctions`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.title_auctions }}</span>
          <!-- <small>{{ item.post }}</small> -->
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="$emit('edit', item)" @remove="$emit('remove', item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CrudButtons from '@/components/buttons/CrudButtons.vue'

export default {
  components: {
    CrudButtons,
  },
  props: {
    auctions: Array,
    search: String,
    loading: Boolean,
  },
  setup() {
    return {
      headers: [
        { text: 'TÍTULO', value: 'title_auctions' },
        { text: 'FECHA DE FINALIZACIÓN', value: 'end_date' },
        { text: 'FECHA REGISTRO', value: 'created_at' },
        { text: 'ULTIMA ACTUALIZACION', value: 'updated_at' },
        { text: '', value: 'actions', align: 'right' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #00aca0;
  padding: 10px;
  margin: 10px;
}

.icon-service img {
  max-width: 50px;
  max-height: 50px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>